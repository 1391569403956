import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <div>
            <StaticQuery
              query={graphql`
                query FooterQuery {
                  imageJusProg: file(relativePath: { eq: "jusprog-age-xml-18.png" }) {
                    childImageSharp {
                      fixed(width: 156) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              `}
              render={data => (
                <a href="https://www.jugendschutzprogramm.de/download" rel="nofollow">
                  <Img position="absolute" fixed={data.imageJusProg.childImageSharp.fixed} alt="Jugendschutzprogramm" />
                </a>
              )}
            />
          </div>
          <p>Anruf erst ab 18 Jahren gestattet.</p>
          <p>Keine realen Treffen, nur Telefonerotik.</p>
          <p>
            Die angezeigten Bilder dienen nur der Visualisierung und Werbung, sie stellen nicht die Gesprächspartnerinnen dar. Alle darauf
            abgebildeten Personen waren zum Zeitpunkt der Aufnahme mindestens 18 Jahre alt.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
